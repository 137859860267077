<template>
  <div>
    <div class="title">
      <div class="warp">
        <p>智能指纹办公抽屉柜锁Y03L</p>
        <el-radio-group v-model="radio" @input="getValue">
          <el-radio label="1">详情</el-radio>
          <el-radio label="2">参数</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="container">
      <div v-show="show">
        <div style="overflow: hidden" class="sdevice hidden-md-and-down">
          <ul>
            <li>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03L/Y03L锂电池banner.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow"
              >
                <p>多种开锁方式</p>
                <span
                  class="animate__animated animate__fadeInUp animate__delay-700ms 700ms"
                  >功能强大，融合多项科技于一身</span
                >
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/2.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow2"
              >
                <p>智能指纹办公抽屉柜锁全面融入日常工作</p>
                <span
                  class="animate__animated animate__fadeInUp animate__delay-700ms 700ms"
                  >工作中使用指纹就能轻松开锁，大幅提高工作体验</span
                >
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/3.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow3"
              >
                <p>指纹一触即开 方便且安全</p>
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/4.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp title2"
                v-show="productImgShow4"
              >
                <p>指纹头扁平设计</p>
                <span
                  class="animate__animated animate__fadeInUp animate__delay-700ms 700ms"
                  >视觉上既美观，又彰显科技感</span
                >
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/5.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow5"
              >
                <p>充电锂电池供电方式</p>
                <span
                  style="color: #000"
                  class="animate__animated animate__fadeInUp animate__delay-700ms 700ms"
                  >充电一次可用4个月，设备低电量时，将会发出低电量提示音。</span
                >
                <div
                  class="recharge animate__animated animate__fadeInUp animate__delay-1s 1s"
                >
                  <span style="color: #000">20%低电量提醒</span>
                  <span style="color: #000">APP电量显示</span>
                  <span style="color: #000">充电一次用很久</span>
                </div>
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03L/10备份 5.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp title4"
                v-show="productImgShow6"
              >
                <p>宇起自研指纹识别算法</p>
                <span
                  class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  style="margin-left: 2.575vw"
                >
                  指纹算法拥有自学习能力，指纹开锁越用越灵敏
                </span>
                <div class="box commodity">
                  <p
                    class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  >
                    <img src="../../assets/mock/Y03B/yanjing.png" alt="" />
                    识别速度＜0.5S
                  </p>
                  <p
                    class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  >
                    <img src="../../assets/mock/Y03B/zhiwen-4.png" alt="" />
                    指纹容量100枚
                  </p>
                  <p
                    class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  >
                    <img src="../../assets/mock/Y03B/编组.png" alt="" />
                    360°全面识别
                  </p>
                </div>
              </div>
              <img src="../../assets/mock/Y03B/7.jpg" alt="" />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow7"
                style="color: #fff"
              >
                <p>全新指纹识别专用芯片</p>
                <span
                  style="color: #fff"
                  class="animate__animated animate__fadeInUp animate__delay-500ms 500ms"
                >
                  全新的指纹识别专用芯片，拥有高性能内核，提升了指纹算法的运算速度。<br />
                  芯片还拥有指纹信息底层自动加密储存功能，保障用户指纹信息安全。
                </span>
              </div>
              <div
                class="commodity navList animate__animated animate__fadeInUp"
                v-show="productImgShow13"
              >
                <p class="p1">
                  <img
                    style="width: 2.2396vw"
                    src="../../assets/mock/Y03B/gaoxingneng.png"
                    alt=""
                  /><span>高性能</span>
                </p>
                <p class="p2">
                  <img
                    style="width: 2.2396vw"
                    src="../../assets/mock/Y03B/anquan1.png"
                    alt=""
                  /><span>安全可靠</span>
                </p>
                <p class="p3">
                  <img
                    style="width: 2.2396vw"
                    src="../../assets/mock/Y03B/jichengguanli.png"
                    alt=""
                  /><span>高集成</span>
                </p>
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/8.jpg"
                alt=""
              />
            </li>
            <!-- <li>
          <div
            class=" commodity animate__animated animate__fadeInUp "
            v-show="productImgShow11"
          >
            <p>低功耗设备 续航无忧</p>
            <span
              style="color: #000000;"
              class=" animate__animated animate__fadeInUp animate__delay-1s 1s"
              >供电采用2节5号电池，使用时间更长，更为方便</span
            >
          </div>
          <div class="endurance" v-show="productImgShow11">
            <p class="p1 animate__animated animate__fadeInUp">低电量提醒</p>
            <p class=" p2 animate__animated animate__fadeInUp">
              3年超长待机
            </p>
            <p class=" p3 animate__animated animate__fadeInUp">
              充电锂电池
            </p>
            <p class=" p4 animate__animated animate__fadeInUp">
              低维护成本
            </p>
          </div>
          <img src="../../assets/image/Y03B/9.jpg" alt="" />
        </li> -->
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow8"
              >
                <p>配备专用应急供电插头</p>
                <span
                  style="color: #000000"
                  class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  >即使遇到锁没电也不用担心开不了锁<br />
                  我们可以使用日常USB Type-C充电线插上宇起应急供电插头开锁</span
                >
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/10.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp title3"
                v-show="productImgShow9"
              >
                <p style="color: #fff">宇起APP全方位支持设备管理</p>
              </div>
              <div class="manage commodity">
                <div
                  class="box1 animate__animated animate__fadeInUp"
                  v-show="productImgShow14"
                >
                  <h3>用户授权</h3>
                  <p><span style="font-size: 6vw">·</span>添加用户</p>
                  <p><span style="font-size: 6vw">·</span>授权用户</p>
                  <p><span style="font-size: 6vw">·</span>一站式管理</p>
                </div>
                <div
                  class="box2 animate__animated animate__fadeInUp"
                  v-show="productImgShow15"
                >
                  <h3>多人开锁</h3>
                  <p>
                    <span style="font-size: 6vw">·</span
                    >用于安全要求高的开锁场景
                  </p>
                </div>
                <div
                  class="box3 animate__animated animate__fadeInUp"
                  v-show="productImgShow16"
                >
                  <h3>物品管理</h3>
                  <p><span style="font-size: 6vw">·</span>快速添加</p>
                  <p><span style="font-size: 6vw">·</span>快速查找</p>
                  <p><span style="font-size: 6vw">·</span>位置管理</p>
                </div>
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/11.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow10"
              >
                <p>极简的安装方式</p>
                <span
                  style="color: #000000"
                  class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  >传统的三联柜可直接替换，小白也能快速上手，办公柜秒变智能柜，<br />
                  厂商也可以在生产三联柜时选择安装宇起智能指纹锁</span
                >
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/12.jpg"
                alt=""
              />
            </li>
            <li>
              <div
                class="commodity animate__animated animate__fadeInUp"
                v-show="productImgShow12"
              >
                <p>多色可选 经典持续传承</p>
                <span
                  style="color: #000"
                  class="animate__animated animate__fadeInUp animate__delay-1s 1s"
                  >颜值担当，配色细腻，高级质感</span
                >
              </div>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03B/13.jpg"
                alt=""
              />
            </li>
            <li>
              <img
                style="width: 100%"
                src="../../assets/mock/Y03L/14.jpg"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
      <div style="min-height: 80vh" v-show="show" class="hidden-lg-and-up">
        <img
          style="width: 100%; min-height: 80vh"
          src="../../assets/mock/shop/1（带字）.jpg"
          alt=""
        />
      </div>
      <div v-show="!show">
        <img style="width: 100%" src="../../assets/mock/Y03L/14.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "1",
      list: [
        {
          Product: "Y02B",
        },
      ],
      show: true,
      productImgShow: false,
      productImgShow2: false,
      productImgShow3: false,
      productImgShow4: false,
      productImgShow5: false,
      productImgShow6: false,
      productImgShow7: false,
      productImgShow8: false,
      productImgShow9: false,
      productImgShow10: false,
      productImgShow11: false,
      productImgShow12: false,
      productImgShow13: false,
      productImgShow14: false,
      productImgShow15: false,
      productImgShow16: false,
      productImgShow17: false,
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    getValue(value) {
      if (value == 1) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    toShopping() {
      this.$router.push("/shopping");
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 600) {
        this.productImgShow = true;
      }
      if (scrollTop >= 1700) {
        this.productImgShow2 = true;
      }
      if (scrollTop >= 2800) {
        this.productImgShow3 = true;
      }
      if (scrollTop >= 4100) {
        this.productImgShow4 = true;
      }
      if (scrollTop >= 5000) {
        this.productImgShow5 = true;
      }
      if (scrollTop >= 5400) {
        this.productImgShow17 = true;
      }
      if (scrollTop >= 6300) {
        this.productImgShow6 = true;
      }
      if (scrollTop >= 7200) {
        this.productImgShow7 = true;
      }
      if (scrollTop >= 7600) {
        this.productImgShow13 = true;
      }
      // if (scrollTop >= 8200) {
      //   this.productImgShow11 = true;
      // }
      if (scrollTop >= 8200) {
        this.productImgShow8 = true;
      }
      if (scrollTop >= 9400) {
        this.productImgShow9 = true;
      }
      if (scrollTop >= 11000) {
        this.productImgShow14 = true;
      }
      if (scrollTop >= 11500) {
        this.productImgShow15 = true;
      }
      if (scrollTop >= 12000) {
        this.productImgShow16 = true;
      }
      if (scrollTop >= 12500) {
        this.productImgShow10 = true;
      }
      if (scrollTop >= 13000) {
        this.productImgShow12 = true;
      }
      this.scrollTop = scrollTop;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style lang="scss" scoped>
.isActive {
  color: #3cb59e !important;
}
.title {
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  div {
    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
  }
}
@media only screen and (max-width: 992px) {
  .title {
    position: relative;
    p {
      font-size: 1rem !important;
    }
  }
}
.container {
  height: 100%;
  position: relative;
  .buy {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 3.125rem;
    z-index: 999;
    .sdeviceHeader {
      margin: 0 auto;
      width: 62.5vw;
    }
    h3 {
      float: left;
      color: #ffffff;
    }
    a {
      color: #ffffff;
      float: right;
      padding-right: 2.5rem;
    }
    a:hover {
      color: #3cb59e;
    }
    .buybtn {
      color: #fff;
      background-color: #3cb59e;
      border-color: #3cb59e;
      float: right;
      margin-top: 5px;
      display: block;
    }
  }
  .sdevice {
    ul {
      li {
        height: auto;
        position: relative;
        img {
          display: block;
        }
        p {
          margin: 0;
          width: 100vw;
          overflow: hidden;
        }
        .recharge {
          width: 62.5vw;
          margin: 0 auto;
          margin-top: 1.875rem;
          display: flex;
          justify-content: space-between;
          span {
            font-weight: 400;
            color: #000;
            background-color: #d2c490;
            padding: 0.9375rem 2.5625rem;
            border-radius: 2.4375rem;
          }
        }
        .commodity {
          position: absolute;
          text-align: center;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          top: 8%;
          transform: translate(-50%, -50%);
          p {
            font-size: 3.5938vw;
          }
          span {
            font-size: 1.8229vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #bea167;
            display: block;
            margin: 5px 0;
          }
          .box {
            top: 235%;
            left: 85%;
            text-align: left;
            img {
              width: 2.1875vw;
              height: auto;
              margin-right: 0.8604vw;
            }
            p {
              display: flex;
              font-size: 1.8229vw;
              margin: 1.7188vw 0;
              align-items: center;
              color: #000000;
            }
            color: #3cb59e;
          }
        }
        .trait {
          position: absolute;
          top: 39%;
          left: 6%;
          img {
            width: 2.6563vw;
            margin-right: 2.0833vw;
          }
          p {
            width: auto;
            font-size: 2.0833vw;
            display: flex;
            align-items: center;
            margin-bottom: 4.2188vw;
          }
        }
        .tally {
          position: absolute;
          display: flex;
          top: 30%;
          left: 22%;
          overflow: hidden;
          p {
            width: 3.6042vw;
            font-size: 1.0417vw;
            text-align: center;
            position: relative;
            z-index: 1;
          }
          img {
            width: 0.4167vw;
            height: 7.7083vw;
            display: inline-block;
          }
          span {
            font-size: 1.0417vw;
            color: #000;
          }
        }
        .tally::after {
          content: "";
          display: table;
          clear: both;
        }
        .tally1 {
        }
        .tally2 {
          margin-left: 9.7813vw;
          margin-top: 0.8854vw;
        }
        .tally3 {
          margin-left: 7.7813vw;
        }
        .tally4 {
          margin-left: 14.7813vw;
          padding-bottom: 0.5208vw;
        }
        .tally5 {
          margin-left: 11.7813vw;
          margin-top: 4.0417vw;
        }

        .navList {
          height: 12.5rem;
          width: 100vw;
          p {
            width: 14.2917vw;
            height: 3.0729vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #bea167;
            border-radius: 2.4219vw 3.7083vw 0px 2.4219vw;
            padding: 5px 2.1354vw;
            img {
              display: inline;
              margin-right: 1.0188vw;
            }
            span {
              font-size: 1.8229vw;
              color: #fff;
            }
          }
          .p1 {
            position: absolute;
            top: 206%;
            left: 15%;
          }
          .p2 {
            position: absolute;
            top: 310%;
            left: 67%;
            border-radius: 3.4219vw 2.7083vw 2.7083vw 0;
          }
          .p3 {
            position: absolute;
            top: 310%;
            left: 18%;
          }
        }
        .navList ::after {
          content: "";
          display: block;
          clear: both;
        }
        .endurance {
          p {
            width: 13.2917vw;
            height: 3.0729vw;
            display: flex;
            align-items: center;
            background-color: #bea167;
            border-radius: 2.4219vw 3.7083vw 0px 2.4219vw;
            padding: 5px 2.1354vw;
            font-size: 1.8229vw;
            color: #fff;
            justify-content: center;
          }
          .p1 {
            position: absolute;
            top: 18vw;
            left: 20vw;
          }
          .p2 {
            position: absolute;
            top: 29vw;
            left: 14vw;
          }
          .p3 {
            position: absolute;
            top: 22vw;
            left: 69vw;
            border-radius: 3.4219vw 2.7083vw 2.7083vw 0;
          }
          .p4 {
            position: absolute;
            top: 37vw;
            left: 60vw;
            border-radius: 3.4219vw 2.7083vw 2.7083vw 0;
          }
        }
        .manage {
          width: 100%vw;
          position: relative;
          h3 {
            color: #fff;
            font-size: 2.7604vw;
          }
          p {
            color: #fff;
            font-size: 2.0313vw;
            display: flex;
            align-items: center;
            line-height: 3.2604vw;
          }
          span {
            color: #fff;
            margin-right: 0.3604vw;
          }
          .box1 {
            position: absolute;
            width: 11.4583vw;
            top: 75.125vw;
            left: 80%;
            text-align: left;
          }
          .box2 {
            position: absolute;
            width: 11.4583vw;
            top: 107.125vw;
            left: 109%;
            text-align: left;
          }
          .box3 {
            position: absolute;
            width: 11.4583vw;
            top: 131.125vw;
            left: 78%;
            text-align: left;
          }
        }
        .title2 {
          top: 18%;
          left: -30%;
        }
        .title3 {
          top: 5%;
        }
        .title4 {
          top: 18%;
          left: 20%;
        }
      }
    }
  }
}
::v-deep .el-radio__input {
  display: none;
}
::v-deep .el-radio__label {
  color: #fff;
}
</style>
